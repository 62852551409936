.sun-editor .se-toolbar {
  outline: none;
  padding: 5px 3px;
  margin-bottom: 10px;
}
.sun-editor .se-toolbar-cover { /*disabled toolbar styling*/
  background-color: #F5F5F5;
}
/* .sun-editor .se-btn-tray {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
} */
.sun-editor .se-btn-module {
  /* display: inline-flex;
  flex-grow: 1;
  justify-content: space-between; */
  margin-left: 3px;
  margin-right: 3px;
}
.sun-editor .se-btn-module-border:hover {
  border-color: #164c93;
}
.sun-editor ul.se-menu-list {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  margin: 0px;
}
.sun-editor .se-menu-list li {
  display: flex;
  flex-grow: 1;
  float: none;
}
/* WARNING FOR IMAGE TOTAL LIMIT*/
.sun-editor .se-notice span {
  visibility: hidden;
}
.sun-editor .se-notice span:before {
  visibility: visible;
  content: "Maximum image size(2MB) exceeded: image not loaded."
}
.sun-editor .se-btn {
  border-radius: 0px;
  margin: 0px!important;
  display: flex;
  flex-grow: 1;
}
.sun-editor .se-svg, .sun-editor button>svg {
  height: 11px;
  width: 11px;
}
.sun-editor button .txt {
  font-size: 11px;
}
.sun-editor .se-btn:enabled:hover {
  background-color: rgba(119, 197, 253, 0.6);
}
.sun-editor .se-btn:enabled.on:focus,
.sun-editor .se-btn:enabled.on:hover,
.sun-editor .se-btn:enabled.on {
  background-color: #78c5fd;
}
  /* .sun-editor-editable is applied to DisplayHTML */
.sun-editor-editable {
  /* overwriting color #333 with MUI default black text color */
  color: #000000de; 
  /* overwriting padding 16px*/
  padding: 0;
  font-family: Arial, Helvetica, sans-serif
}
  /* .sun-editor .se-wrapper .se-wrapper-wysiwyg is NOT applied to DisplayHTML */
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  /* Adding padding in editor wrapper to compensate removed padding in .sun-editor-editable */
  padding: 16px;
}

/* Custom SunEditor Plugin styles */
.sun-editor a.se-content-cfrLink-button {
  background-color: #fdfdfe;
  background-image: url('data:image/svg+xml;utf8, <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"2 2 20 20\" shape-rendering=\"crispedges\"> <path d=\"M5.2496 8.0688l2.83-2.8268 14.134 14.15-2.83 2.8268zM9.4857 3.8272l2.828-2.8288 5.6576 5.656-2.828 2.8288zM.9989 12.3147l2.8284-2.8284L9.484 15.143l-2.8284 2.8284zM1 21h12v2H1z\"> </path> </svg>');
  background-position: 4px center;
  background-repeat: no-repeat;
  background-size: 14px;
  border: 1px solid #eee;
  border-radius: 2px;
  color: #000;
  display: inline-flex;
  margin: 0 0.3em;
  padding: 0 4px 0 24px;
}

.sun-editor a.se-content-cfrLink-button:hover {
  text-decoration: none;
}

.sun-editor a.se-content-cfrLink-button + a.se-content-cfrLink-button {
  margin-left: 0;
}

.sun-editor .se-controller .se-controller-header {
  margin: 0;
  padding: 8px 8px 0;
  text-align: center;
}

.sun-editor .se-controller .se-btn-group {
  justify-content: center;
}
