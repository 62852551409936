.App {
  text-align: left;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.9rem;
}

h6 {
  font-size: 0.8rem;
}
