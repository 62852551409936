body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: overlay;
  overflow-x: overlay;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}
::-webkit-scrollbar:horizontal {
  height: 10px;
  background-color: transparent;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  padding: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #164c93;
  background: linear-gradient(90deg, #164c93 0%, #175db5 50%, #164c93 100%);
  -webkit-box-shadow: inset 0px 0px 2px 1px #11386c;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #164c93;
  background: linear-gradient(180deg, #164c93 0%, #175db5 50%, #164c93 100%);
  -webkit-box-shadow: inset 0px 0px 2px 1px #11386c;
  border-radius: 10px;
}
/* Hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(64,145,206);
  background: linear-gradient(90deg, #4091ce 0%, #78c5fd 50%, #4091ce 100%);
  -webkit-box-shadow: inset 0px 0px 2px 1px #007acc;
}
::-webkit-scrollbar-thumb:horizontal:hover {
  background: rgb(64,145,206);
  background: linear-gradient(180deg, #4091ce 0%, #78c5fd 50%, #4091ce 100%);
  -webkit-box-shadow: inset 0px 0px 2px 1px #007acc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
